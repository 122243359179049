@tailwind base;
@tailwind components;
@tailwind utilities;

body {
 width: 100%;
 height: 100%;
}

/* Make a custom scrollbar */
::-webkit-scrollbar {
 height: 8px !important;
 width: 8px !important;
}

::-webkit-scrollbar-thumb {
 background: linear-gradient(transparent, #6a64ff) !important;
 border-radius: 3px !important;
}

::-webkit-scrollbar-thumb:hover {
 background: linear-gradient(transparent, #50c8ff) !important;
}

ion-select {
 max-width: 100% !important;
}
